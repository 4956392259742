<template>
  <div class="task-content">
    <TaskOrder
      @buttonCLick="confirmModalShow = true"
      type="enterprise"
      @releaseCLick="releaseCLick"
      @complete="complete"
      ref="taskOrder"
    ></TaskOrder>
    <div
      class="confirm-modal-bb"
      v-show="confirmModalShow"
      @click="confirmModalShow = false"
    >
      <div class="confirm-modal">
        <div class="title">确认完成</div>
        <div class="tips">点击确认按钮表示此订单完结，不可撤回</div>
        <div class="confirm" @click="confirmComplete">
          <img src="../../assets/images/enterprise/task/confirm.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskOrder from "../../components/TaskOrder.vue";
export default {
  components: {
    TaskOrder,
  },
  data() {
    return {
      confirmModalShow: false,
      project: {},
    };
  },
  methods: {
    releaseCLick(project) {
      let { type, data } = project;
      let params = {
        project_id: data.id,
        user_id: data.painter_user_id,
      };
      this.$api.projectTestimg.detail(params).then((res) => {
        if (type == "router") {
          this.$router.push({
            name: "/enterprise/communication",
            params: { type: "centerTypeOfImg", data: res.data },
          });
        }
      });
    },
    // 完成此单
    complete(project) {
      this.confirmModalShow = true;
      this.project = project;
    },
    // 确定完成此单
    confirmComplete() {
      let data = { id: this.project.id, user_id: this.project.painter_user_id };
      this.$api.project.complete(data).then(() => {
        this.confirmModalShow = false;
        this.project.state = 3;
        this.$refs.taskOrder.getPhaseList(this.project);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.task-content {
  position: relative;
  .confirm-modal-bb {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  .confirm-modal {
    background: url("../../assets/images/enterprise/task/bg.png") no-repeat #000;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-30%, -50%);
    padding: 1vw 5vw;
    .title {
      font-size: 1.5vw;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #fdf48f;
      margin-top: 2vw;
    }
    .tips {
      font-size: 0.1vw;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      margin: 2vw 0;
    }
    .confirm {
      width: 9vw;
      margin: 0 auto;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
}
</style>
